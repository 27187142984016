import React from 'react'
import {
  IconBrain,
  IconChartBar,
  IconDatabaseSearch,
  IconFingerprint,
  IconShieldCheck,
  IconUserCheck,
} from '@tabler/icons-react'

export default function Products() {
  const features = [
    {
      icon: IconFingerprint,
      title: '身份风险评估',
      description: '三要素校验、多头借贷、异常名单、风险评分',
      color: 'bg-blue-500',
    },
    {
      icon: IconDatabaseSearch,
      title: '全面数据分析',
      description: '不良信息、案件信息、限制高消费、多维度风险指标',
      color: 'bg-indigo-500',
    },
    {
      icon: IconBrain,
      title: '自研风控模型',
      description: '深度学习算法、精准风险预测、持续数据更新',
      color: 'bg-violet-500',
    },
    {
      icon: IconUserCheck,
      title: '人才背调服务',
      description: '求职者背景调查、降低用人风险、优化招聘决策',
      color: 'bg-purple-500',
    },
    {
      icon: IconShieldCheck,
      title: '家政服务评估',
      description: '从业人员风险评估、社交风险标签、案件记录查询',
      color: 'bg-pink-500',
    },
    {
      icon: IconChartBar,
      title: '小微企业服务',
      description: '企业风险评估、履约能力分析、降低交易风险',
      color: 'bg-red-500',
    },
  ]

  return (
    <section id="features" className="py-20 bg-gradient-to-b from-[#f8fafc] to-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <span className="inline-block py-1 px-3 bg-blue-100 text-blue-600 rounded-full text-sm font-medium tracking-wide mb-3">
            产品特点
          </span>
          <h2 className="text-3xl md:text-4xl font-bold text-slate-800 mb-4">全面的风险评估体系</h2>
          <p className="text-slate-600 max-w-2xl mx-auto">
            基于自研大数据风控模型，为不同行业场景提供定制化的风险评估解决方案
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="relative bg-white rounded-xl p-8 border border-slate-200 shadow-lg hover:shadow-xl transition-all duration-300 group overflow-hidden"
            >
              <div
                className={`absolute top-0 right-0 w-24 h-24 -mr-10 -mt-10 rounded-full ${feature.color} opacity-10 blur-2xl group-hover:opacity-20 transition-opacity duration-300`}
              ></div>

              <div className={`w-12 h-12 rounded-lg flex items-center justify-center mb-6 ${feature.color}`}>
                <feature.icon size={24} className="text-white" />
              </div>

              <h3 className="text-xl font-semibold text-slate-800 mb-3 relative z-10">{feature.title}</h3>
              <p className="text-slate-600 relative z-10">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
