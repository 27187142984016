import React from 'react'
import { IconChecks, IconClockHour4, IconShieldCheck, IconUsers } from '@tabler/icons-react'

export default function Facts() {
  const stats = [
    {
      value: '1000+',
      label: '企业客户',
      icon: IconUsers,
      color: 'from-blue-500 to-indigo-600',
    },
    {
      value: '10000+',
      label: '查询报告',
      icon: IconChecks,
      color: 'from-violet-500 to-purple-600',
    },
    {
      value: '98%',
      label: '客户满意度',
      icon: IconShieldCheck,
      color: 'from-pink-500 to-rose-600',
    },
    {
      value: '<2分钟',
      label: '报告生成时间',
      icon: IconClockHour4,
      color: 'from-amber-500 to-orange-600',
    },
  ]

  return (
    <section className="relative py-20">
      {/* 背景 */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#f8fafc] to-slate-100 skew-y-3 transform-gpu -z-10"></div>
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[url('https://grainy-gradients.vercel.app/noise.svg')] opacity-20 mix-blend-overlay"></div>
        <div className="absolute right-0 bottom-0 w-[600px] h-[600px] bg-gradient-to-t from-blue-200 to-transparent opacity-30 rounded-full blur-3xl"></div>
      </div>

      <div className="container mx-auto px-6 relative z-10">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <span className="inline-block py-1 px-3 bg-blue-100 text-blue-600 rounded-full text-sm font-medium tracking-wide mb-3">
            为什么选择我们
          </span>
          <h2 className="text-3xl md:text-4xl font-bold text-slate-800 mb-4">专业、高效、可靠的风险评估服务</h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="relative bg-white rounded-xl p-6 border border-slate-200 shadow-lg overflow-hidden group"
            >
              <div
                className={`absolute -top-10 -right-10 w-40 h-40 rounded-full bg-gradient-to-br ${stat.color} opacity-10 blur-3xl group-hover:opacity-20 transition-opacity duration-300`}
              ></div>

              <div className="mb-4">
                <div
                  className={`w-12 h-12 rounded-xl bg-gradient-to-br ${stat.color} flex items-center justify-center`}
                >
                  <stat.icon size={24} className="text-white" />
                </div>
              </div>

              <h3 className="text-3xl font-bold text-slate-800 mb-1">{stat.value}</h3>
              <p className="text-slate-600">{stat.label}</p>
            </div>
          ))}
        </div>

        <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white rounded-xl p-6 border border-slate-200 shadow-lg">
            <h3 className="text-lg font-semibold text-slate-800 mb-3">数据安全保障</h3>
            <p className="text-slate-600">严格的数据加密机制，全面的隐私保护，保障用户信息安全</p>
          </div>

          <div className="bg-white rounded-xl p-6 border border-slate-200 shadow-lg">
            <h3 className="text-lg font-semibold text-slate-800 mb-3">精准风险预测</h3>
            <p className="text-slate-600">自研风控模型，多维度数据分析，提供精准的风险评估结果</p>
          </div>

          <div className="bg-white rounded-xl p-6 border border-slate-200 shadow-lg">
            <h3 className="text-lg font-semibold text-slate-800 mb-3">专业技术支持</h3>
            <p className="text-slate-600">大数据专家和风控顾问，提供全方位的技术与服务支持</p>
          </div>
        </div>
      </div>
    </section>
  )
}
