import React from 'react'
import { AppShell } from '@mantine/core'
import Hero from './components/Hero'
import Feature from './components/Feature'
import Products from './components/Products'
import Facts from './components/Facts'
import Footer from './components/Footer'

export default function Home() {
  return (
    <AppShell>
      <AppShell.Main>
        <Hero />
        <Products />
        <Facts />
        <Feature />
        <Footer />
      </AppShell.Main>
    </AppShell>
  )
}
