import React, { useState } from 'react'
import { useForm } from '@mantine/form'
import { Box, Button, Checkbox, Flex, Group, TextInput, Title } from '@mantine/core'
import { IconPhone, IconShieldCheck } from '@tabler/icons-react'
import useVerificationCodeTimer from '../../hooks/useVerificationCodeTimer'
import NumberTag from './NumberTag'
import { recharge } from '../../api/merchantService'
import { Dialog } from 'antd-mobile'

export default function RechargeForm() {
  const [loading, setLoading] = useState(false)
  const { label, startTimer, isActive } = useVerificationCodeTimer()
  const [rechargeAmount, setRechargeAmount] = useState<string>('500')

  const form = useForm({
    initialValues: {
      phone: '',
      code: '',
      termsOfService: false,
    },
    validate: {
      phone: (value) =>
        value === '' ? '请输入代理商手机号' : !/^1[3-9]\d{9}$/.test(value) ? '请输入正确的手机号' : null,
      code: (value) => (value === '' ? '请输入验证码' : value.length !== 6 ? '请输入6位的验证码' : null),
    },
  })

  const rechargeAmounts = [
    { value: '100', label: '¥100' },
    { value: '200', label: '¥200' },
    { value: '500', label: '¥500' },
    { value: '1000', label: '¥1000' },
    { value: '2000', label: '¥2000' },
    { value: '5000', label: '¥5000' },
  ]

  const agreeTermsOfService = () => {
    return (
      <>
        我已阅读并同意
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://u.sjcyx.com/userAgreement.html', '_blank')
          }}
        >
          《用户协议》
        </span>
        和
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://u.sjcyx.com/privacyPolicy.html', '_blank')
          }}
        >
          《隐私政策》
        </span>
      </>
    )
  }

  const userRecharge = (values: any) => {
    setLoading(true)
    recharge({
      ...values,
      amount: rechargeAmount,
    })
      .then((data) => {
        const div = document.createElement('div')
        div.innerHTML = data
        document.body.appendChild(div)
        const form = div.querySelector('form')
        form?.submit()
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box>
      <Title order={4}>优查查代理商充值</Title>
      <form
        onSubmit={form.onSubmit((values) => {
          if (!values.termsOfService) {
            Dialog.confirm({
              content: agreeTermsOfService(),
              confirmText: '同意并充值',
              cancelText: '不同意',
              onConfirm: () => {
                form.setFieldValue('termsOfService', true)
                userRecharge(values)
              },
            }).then()
          } else {
            userRecharge(values)
          }
        })}
      >
        <TextInput
          mt="md"
          placeholder="请输入代理商手机号"
          leftSection={<IconPhone size={16} />}
          {...form.getInputProps('phone')}
        />
        <TextInput
          mt="md"
          placeholder="请输入验证码"
          leftSection={<IconShieldCheck size={16} />}
          rightSectionWidth={100}
          maxLength={6}
          rightSection={
            <Button
              size="xs"
              variant="subtle"
              onClick={() => {
                const phone = form.values.phone
                const result = form.validateField('phone')
                if (result.hasError) {
                  return
                }
                startTimer({ phone: phone, type: 7 })
              }}
              disabled={isActive}
            >
              {label}
            </Button>
          }
          {...form.getInputProps('code')}
        />
        <Flex mih={50} gap="sm" justify="flex-start" align="center" direction="row" wrap="wrap" mt={'md'}>
          {rechargeAmounts.map((item) => (
            <NumberTag
              key={item.value}
              label={item.label}
              isSelected={rechargeAmount === item.value}
              onClick={(value) => {
                setRechargeAmount(value)
              }}
              value={item.value}
            />
          ))}
        </Flex>
        <Checkbox
          my="md"
          color={'#6F84FC'}
          label={agreeTermsOfService()}
          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
        />
        <Group justify="space-between" mt="md">
          <Button
            loading={loading}
            type="submit"
            fullWidth
            radius="md"
            size={'md'}
            styles={{
              root: {
                backgroundColor: '#6F84FC',
              },
            }}
          >
            {`¥${rechargeAmount} 立即充值`}
          </Button>
        </Group>
      </form>
    </Box>
  )
}
