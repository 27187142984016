import React, { useEffect, useState } from 'react'
import '../App.css'
import QueryReportForm from './components/QueryReportForm'
import { Image, Stack } from '@mantine/core'
import ReportElements from './components/ReportElements'
import RightFloating from '../components/RightFloating'
import FloatingButton from '../components/FloatingButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { facade } from '../api/orderService'
import { TemplateMerchantInterface } from '../typings/orderTypes'
import InterceptorWrapper from '../components/InterceptorWrapper'
import { getDictByKey } from '../api/dictionaryService'
import { Dialog, Toast } from 'antd-mobile'

export default function QueryReport() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const linkId = searchParams.get('linkId') || ''
  const [template, setTemplate] = useState<TemplateMerchantInterface>()

  useEffect(() => {
    const init = async () => {
      if (linkId === '') {
        navigate('/login')
        return
      }

      try {
        const whitelistStr = await getDictByKey('key_host_whitelist')
        const whitelist = whitelistStr.split(',')
        const currentHost = window.location.host

        if (!whitelist.includes(currentHost)) {
          Dialog.show({
            content: '当前二维码不可用，请联系商户更换新的查询二维码。',
            closeOnMaskClick: false,
          })
          return
        }

        const res = await facade(linkId)
        setTemplate(res)
      } catch (err) {
        Toast.show('系统错误，请稍后再试')
      }
    }

    init().then()
  }, [linkId, navigate])

  return (
    <InterceptorWrapper>
      <div className="max-w-xl m-auto">
        <div className="bg-[#3A55DD] pb-4">
          <Image src="./banner.png" />
          <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
            <QueryReportForm template={template} />
          </div>
          <div className="mx-4 bg-white rounded-3xl px-6 py-5">
            <ReportElements />
          </div>
          <RightFloating>
            <Stack>
              <FloatingButton
                color={'green'}
                label={'历史报告'}
                onClick={() => {
                  navigate('/orders')
                }}
              />
            </Stack>
          </RightFloating>
        </div>
      </div>
    </InterceptorWrapper>
  )
}
