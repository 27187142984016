import React from 'react'
import { IconMail, IconPhone } from '@tabler/icons-react'

export default function Footer() {
  return (
    <footer className="bg-slate-100 text-slate-600 pt-16 pb-10">
      <div className="container mx-auto px-6">
        {/* 顶部部分 */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mb-16">
          <div>
            <div className="mb-6">
              <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text">
                优查查
              </span>
              <span className="text-sm ml-2 bg-blue-100 text-blue-600 px-2 py-0.5 rounded-md">风险评估</span>
            </div>
            <p className="text-sm mb-6">
              通过大数据风控模型和深度学习算法，为企业和个人提供全面的风险评估报告和风险管理解决方案
            </p>
          </div>

          <div>
            <h3 className="text-slate-800 font-semibold mb-4">产品服务</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  个人风险评估
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  人才背调服务
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  小微企业风险报告
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  租赁风险评估
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-slate-800 font-semibold mb-4">解决方案</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  租赁服务
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  家政服务
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  人才背调
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                  个人风险自查
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-slate-800 font-semibold mb-4">联系我们</h3>
            <ul className="space-y-3 text-sm">
              <li className="flex items-center">
                <IconPhone size={16} className="mr-2 text-blue-600" />
                4000799168
              </li>
              <li className="flex items-center">
                <IconMail size={16} className="mr-2 text-blue-600" />
                office@sjcyx.com
              </li>
            </ul>
            <div className="mt-6">
              <div className="flex space-x-3">
                <a
                  href="https://u.sjcyx.com/queryReport?linkId=10140504619395260417"
                  target="_blank"
                  className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors duration-200"
                >
                  立即查询
                </a>
                <a
                  href="https://www.pgyer.com/isaac"
                  target="_blank"
                  className="px-4 py-2 border border-blue-500 hover:border-blue-600 text-blue-600 hover:text-blue-700 text-sm rounded-lg transition-colors duration-200"
                >
                  下载App
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* 底部版权 */}
        <div className="border-t border-slate-200 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm mb-4 md:mb-0">© 2024 优查查·风险评估. All rights reserved.</p>
            <div className="flex space-x-6 text-sm">
              <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                隐私政策
              </a>
              <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                服务条款
              </a>
              <a href="#" className="hover:text-blue-600 transition-colors duration-200">
                法律声明
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
