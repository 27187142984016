import LabeledTitle from '../../../components/LabeledTitle'
import { Stack } from '@mantine/core'
import React from 'react'
import ReactECharts from 'echarts-for-react'
import PageEmpty from '../../../components/PageEmpty'
import { EChartsOption } from 'echarts'

interface RelatedCasesStatisticsProps {
  data: any
}

export default function RelatedCasesStatistics({ data }: RelatedCasesStatisticsProps) {
  const civilCount = (data?.court_case_info?.civil ?? []).length
  const criminalCount = (data?.court_case_info?.criminal ?? []).length
  const otherCount = (data?.court_case_info?.other ?? []).length
  const option: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: [
          { value: civilCount, name: `民事案件${civilCount}个` },
          { value: criminalCount, name: `刑事案件${criminalCount}个` },
          { value: otherCount, name: `其他案件${otherCount}个` },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  }
  return (
    <Stack>
      <LabeledTitle title={'关联案件统计'} />
      {civilCount > 0 || criminalCount > 0 || otherCount > 0 ? (
        <ReactECharts option={option} />
      ) : (
        <PageEmpty image={'/good.png'} title={'暂无数据'} size={80} />
      )}
    </Stack>
  )
}
