import React from 'react'

export default function Hero() {
  return (
    <section className="relative min-h-[100vh] bg-gradient-to-br from-[#f8fafc] to-[#e2e8f0] overflow-hidden flex items-center justify-center">
      {/* 抽象背景图形 */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(circle_at_50%_120%,#3b82f6,transparent_55%)]"></div>
        <div className="absolute bottom-0 right-0 w-full h-full bg-[radial-gradient(circle_at_30%_30%,#7c3aed,transparent_45%)]"></div>
      </div>

      <div className="container mx-auto px-6 py-10 relative z-10">
        <div className="max-w-4xl mx-auto text-center">
          <span className="inline-block py-1 px-3 bg-blue-100 text-blue-600 rounded-full text-sm font-medium tracking-wide mb-6">
            智能风控解决方案
          </span>
          <h1 className="text-4xl md:text-6xl font-bold mb-6 text-slate-800">
            优查查
            <span className="mt-3 block text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
              大数据风险评估
            </span>
          </h1>
          <p className="text-lg text-slate-600 mb-10 max-w-2xl mx-auto">
            为个人风险、背调、家政服务和租赁行业提供全面的风险评估报告，助力商业决策
          </p>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="https://u.sjcyx.com/queryReport?linkId=10140504619395260417"
              target="_blank"
              className="inline-block py-3 px-8 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white font-medium rounded-lg shadow-lg shadow-blue-600/30 transition-all duration-300"
            >
              立即查询
            </a>
            <a
              href="https://www.pgyer.com/isaac"
              target="_blank"
              className="inline-block py-3 px-8 border-2 border-blue-500/50 hover:border-blue-600 text-blue-600 hover:text-blue-700 font-medium rounded-lg transition-all duration-300"
            >
              下载 App
            </a>
          </div>
        </div>
      </div>

      {/* 底部装饰 */}
      <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-blue-500/30 to-transparent"></div>

      {/* 装饰圆圈 */}
      <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 w-64 h-64 bg-blue-500/5 rounded-full blur-3xl animate-pulse"></div>
    </section>
  )
}
