import ReactECharts from 'echarts-for-react'
import { EChartsOption } from 'echarts'

interface ReportScoreProps {
  data: any
  title: string
}

export default function ReportScore({ data, title }: ReportScoreProps) {
  const options: EChartsOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        center: ['50%', '75%'],
        radius: '90%',
        min: 0,
        max: 1,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2'],
            ],
          },
        },
        pointer: {
          icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
          length: '12%',
          width: 20,
          offsetCenter: [0, '-60%'],
          itemStyle: {
            color: 'auto',
          },
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2,
          },
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5,
          },
        },
        axisLabel: {
          color: '#fff',
          fontSize: 13,
          distance: -60,
          rotate: 'tangential',
          formatter: function (value: number) {
            if (value === 0.875) {
              return '高'
            } else if (value === 0.625) {
              return '中'
            } else if (value === 0.375) {
              return '低'
            } else if (value === 0.125) {
              return '极低'
            }
            return ''
          },
        },
        title: {
          offsetCenter: [0, '-10%'],
          fontSize: 16,
          color: '#fff',
        },
        detail: {
          fontSize: 30,
          offsetCenter: [0, '-35%'],
          valueAnimation: true,
          formatter: function (value: number) {
            return Math.round(value * 100) + ''
          },
          color: 'inherit',
        },
        data: [
          {
            value: data?.meta_index_score / 100,
            name: title,
          },
        ],
      },
    ],
  }

  return <ReactECharts option={options} />
}
