import { Badge, Box, Button, Center, Divider, Group, Image, Overlay, Stack, Text, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { CheckCircleFill, CheckCircleOutline } from 'antd-mobile-icons'
import { useSearchParams } from 'react-router-dom'
import { payment } from '../api/orderService'
import { getDictByKey } from '../api/dictionaryService'
import { Toast } from 'antd-mobile'
import useWechatAuth from '../hooks/useWechatAuth'

declare global {
  interface Window {
    WeixinJSBridge: {
      invoke: (method: string, params: any, callback: (res: any) => void) => void
    }
  }
}

interface ChannelOption {
  key: string
  title: string
  image: string
  router: string
  desc?: string
  appId?: string
}

interface PayChannel {
  key: string
  router: string
  weight: number
  appId?: string
}

interface PaymentType {
  type: string // 支付类型：alipay/wxpay/fuiou
  title: string // 显示名称
  image: string // 图标
  desc?: string // 描述文字
  channels: PayChannel[]
}

interface WxPayConfig {
  appId: string
  timeStamp: string
  nonceStr: string
  package: string
  signType: string
  paySign: string
}

export default function Payment() {
  const [time, setTime] = useState('5:00')
  const [selected, setSelected] = useState<ChannelOption>()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId') || ''
  const templateName = searchParams.get('templateName') || ''
  const templatePrice = searchParams.get('templatePrice') || ''
  const [channel, setChannel] = useState<ChannelOption[]>([])
  const routers = searchParams.get('routers')?.split(',') || []
  const [showWxAlert, setShowWxAlert] = useState(false)

  const appId = searchParams.get('appId') || ''
  const { wxCode, wxAuthLoading } = useWechatAuth({ appId })

  const isEnvWeixin = /MicroMessenger/i.test(window.navigator.userAgent)

  useEffect(() => {
    getDictByKey('key_pay_channel')
      .then(async (data) => {
        let paymentTypes: PaymentType[] = JSON.parse(data)
        let availableChannels: ChannelOption[] = []

        // 处理每种支付类型
        for (const paymentType of paymentTypes) {
          if (!isEnvWeixin && paymentType.type === 'wxpay') {
            continue
          }
          // 根据传入的 routers 找到对应的 channel
          const matchedChannel = paymentType.channels.find((channel) => routers.includes(channel.router))
          if (matchedChannel) {
            availableChannels.push({
              key: matchedChannel.key,
              title: paymentType.title,
              image: paymentType.image,
              router: matchedChannel.router,
              desc: paymentType.desc,
              appId: matchedChannel.appId,
            })
          }
        }

        setChannel(availableChannels)
        setSelected(availableChannels[0])
      })
      .catch(() => {
        Toast.show('获取支付渠道失败')
      })
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      const [minutes, seconds] = time.split(':').map(Number)
      if (minutes === 0 && seconds === 0) {
        clearInterval(timer)
      } else if (seconds === 0) {
        setTime(`${minutes - 1}:59`)
      } else {
        setTime(`${minutes}:${seconds < 11 ? `0${seconds - 1}` : seconds - 1}`)
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [time])

  const callWxPay = (payConfig: WxPayConfig) => {
    return new Promise((resolve, reject) => {
      if (typeof window.WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        }
      } else {
        onBridgeReady()
      }

      function onBridgeReady() {
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: payConfig.appId,
            timeStamp: payConfig.timeStamp,
            nonceStr: payConfig.nonceStr,
            package: payConfig.package,
            signType: payConfig.signType,
            paySign: payConfig.paySign,
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              resolve(res)
            } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              reject(new Error('取消支付'))
            } else {
              reject(new Error('支付失败'))
            }
          }
        )
      }
    })
  }

  const doPayment = async () => {
    try {
      if (isEnvWeixin && selected?.key.startsWith('alipay')) {
        setShowWxAlert(true)
        return
      }

      setLoading(true)
      const res = await payment(orderId, selected?.router!, wxCode || '')
      if (selected?.key.startsWith('lianlianWxpay')) {
        const parsedRes = JSON.parse(res)
        const payConfig: WxPayConfig = parsedRes.metadata
        await callWxPay(payConfig)
        window.location.href = `/reports/${orderId}`
        return
      }
      const div = document.createElement('div')
      div.innerHTML = res
      document.body.appendChild(div)
      const form = div.querySelector('form')
      form?.submit()
    } catch (error: any) {
      Toast.show(error?.message || '支付失败')
    } finally {
      setLoading(false)
    }
  }

  const CheckListItem = ({
    children,
    checked,
    onChange,
  }: {
    children: React.ReactNode
    checked: boolean
    onChange: () => void
  }) => {
    return (
      <Group justify="space-between" onClick={onChange}>
        {children}
        {checked ? (
          <CheckCircleFill color={'#3A55DD'} fontSize={26} />
        ) : (
          <CheckCircleOutline color={'#3A55DD'} fontSize={26} />
        )}
      </Group>
    )
  }

  if (wxAuthLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-500 mx-auto"></div>
          <div className="mt-4 text-gray-600 text-lg">授权处理中...</div>
        </div>
      </div>
    )
  }

  return (
    <Box pos="relative" style={{ minHeight: '100vh' }}>
      <Stack>
        <Stack align={'center'} p={30}>
          <Title order={4}>{templateName}</Title>
          <Title>{`¥${templatePrice}`}</Title>
          <Stack gap={6} align={'center'}>
            <Text c={'gray'} mt={10}>{`支付剩余时间 ${time}`}</Text>
            <Text c={'gray'}>{`订单编号 ${orderId}`}</Text>
          </Stack>
        </Stack>
        <Divider size={'lg'} />
        <Stack px={20} py={10}>
          <Title order={5}>选择支付方式</Title>
          <Stack mt={10} gap={24}>
            {channel.map((item) => (
              <CheckListItem
                key={item.key}
                checked={selected?.key === item.key}
                children={
                  <Group>
                    <Image h={26} w="auto" src={item.image} />
                    <Text>{item.title}</Text>
                    {item.desc && (
                      <Badge variant="outline" color="#3A55DD" size="lg" radius="sm">
                        {item.desc}
                      </Badge>
                    )}
                  </Group>
                }
                onChange={() => {
                  setSelected(item)
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Group justify="space-between" mt="md" px={20}>
          <Button
            onClick={() => {
              doPayment().then()
            }}
            fullWidth
            radius="md"
            size={'md'}
            loading={loading}
            styles={{
              root: {
                backgroundColor: '#3A55DD',
              },
            }}
          >
            <Text>立即支付</Text>
          </Button>
        </Group>
        <Center px={16}>
          <Stack gap={8}>
            <Text size="sm" c="dimmed">
              1.
              本报告仅作为大数据信息汇总，非央行征信报告，不提供大数据报告外的贷款等金融服务，如有疑问请联系客服，请仔细甄别。
            </Text>
            <Text size="sm" c="dimmed">
              2. 谨防被欺骗、诱导等方式引导查询。若存在被人诱导消费或强制消费情况，请点击右下角联系客服反馈。
            </Text>
          </Stack>
        </Center>
      </Stack>
      {showWxAlert && (
        <Overlay
          color="#000"
          backgroundOpacity={0.65}
          onClick={() => {
            setShowWxAlert(false)
          }}
        >
          <Image src="https://file.sjcyx.com/wx_alert.png" />
        </Overlay>
      )}
    </Box>
  )
}
