import { useEffect, useState } from 'react'
import { getDictByKey } from '../api/dictionaryService'
import { Toast } from 'antd-mobile'

interface PayChannel {
  key: string
  router: string
  weight: number
  appId?: string
}

interface PaymentType {
  type: string
  title: string
  image: string
  desc?: string
  channels: PayChannel[]
}

export interface ChannelOption {
  key: string
  title: string
  image: string
  router: string
  desc?: string
  appId?: string
}

interface UsePaymentChannelsProps {
  orderId?: string
}

export const usePaymentChannels = ({ orderId }: UsePaymentChannelsProps) => {
  const [channels, setChannels] = useState<ChannelOption[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>('')
  const isEnvWeixin = /MicroMessenger/i.test(window.navigator.userAgent)

  // 根据权重随机选择通道
  const selectChannelByWeight = (channels: PayChannel[]): PayChannel => {
    // 计算总权重
    const totalWeight = channels.reduce((sum, channel) => sum + (channel.weight || 0), 0)

    // 生成一个 0 到总权重之间的随机数
    let random = Math.random() * totalWeight

    // 遍历通道，根据权重选择
    for (const channel of channels) {
      random -= channel.weight || 0
      if (random <= 0) {
        return channel
      }
    }

    // 如果出现计算误差，返回第一个通道
    return channels[0]
  }

  // 获取可用的支付渠道
  const getAvailableChannels = async (paymentTypes: PaymentType[]): Promise<ChannelOption[]> => {
    const availableChannels: ChannelOption[] = []

    for (const paymentType of paymentTypes) {
      // 非微信环境跳过微信支付
      if (!isEnvWeixin && paymentType.type === 'wxpay') {
        continue
      }

      // 使用权重随机选择通道
      const selectedChannel = selectChannelByWeight(paymentType.channels)
      if (selectedChannel) {
        availableChannels.push({
          key: selectedChannel.key,
          title: paymentType.title,
          image: paymentType.image,
          router: selectedChannel.router,
          desc: paymentType.desc,
          appId: selectedChannel.appId,
        })
      }
    }

    return availableChannels
  }

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        setLoading(true)
        const data = await getDictByKey('key_pay_channel')
        const paymentTypes: PaymentType[] = JSON.parse(data)
        const availableChannels = await getAvailableChannels(paymentTypes)
        setChannels(availableChannels)
        setError('')
      } catch (err) {
        setError('获取支付渠道失败')
        Toast.show('获取支付渠道失败')
        setChannels([])
      } finally {
        setLoading(false)
      }
    }

    fetchChannels().then()
  }, [orderId, isEnvWeixin])

  // 获取支付路由字符串
  const getRoutersString = (): string => {
    return channels.map((channel) => channel.router).join(',')
  }

  // 获取第一个可用的 appId
  const getFirstAppId = (): string => {
    const wxChannel = channels.find(
      (channel) => channel.key.startsWith('wxpay') || channel.key.startsWith('lianlianWxpay')
    )
    return wxChannel?.appId || ''
  }

  return {
    channels,
    paymentLoading: loading,
    error,
    getRoutersString,
    getFirstAppId,
  }
}
