import React from 'react'
import { IconBuilding, IconBusinessplan, IconSearch, IconUsers, IconUserSearch } from '@tabler/icons-react'

export default function Feature() {
  const solutions = [
    {
      icon: IconBuilding,
      title: '租赁服务',
      description: '帮助租赁企业降低坏账风险，优化租赁决策流程，提升运营效率',
      image: 'https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
    },
    {
      icon: IconUsers,
      title: '家政服务',
      description: '为家政服务平台建立从业人员风险档案，降低雇佣风险，提升服务品质',
      image:
        'https://images.unsplash.com/photo-1581578731548-c64695cc6952?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
    },
    {
      icon: IconUserSearch,
      title: '人才背调',
      description: '帮助企业全面了解应聘者背景，降低用人风险，优化招聘决策流程',
      image:
        'https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
    },
    {
      icon: IconBusinessplan,
      title: '小微企业',
      description: '评估企业履约能力和风险状况，降低交易风险，优化合作决策',
      image: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
    },
    {
      icon: IconSearch,
      title: '个人风险',
      description: '帮助个人了解自身风险状况，及时发现潜在问题，保护个人风险安全',
      image:
        'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
    },
  ]

  return (
    <section className="py-20 bg-slate-50">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <span className="inline-block py-1 px-3 bg-blue-100 text-blue-600 rounded-full text-sm font-medium tracking-wide mb-3">
            行业解决方案
          </span>
          <h2 className="text-3xl md:text-4xl font-bold text-slate-900 mb-4">为不同行业提供定制化服务</h2>
          <p className="text-slate-600 max-w-2xl mx-auto">基于场景化需求，我们为各行业打造专属风险评估解决方案</p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="md:w-2/5 h-60 md:h-auto relative overflow-hidden">
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                />
              </div>
              <div className="md:w-3/5 p-8">
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-lg bg-blue-100 flex items-center justify-center mr-4">
                    <solution.icon size={20} className="text-blue-600" />
                  </div>
                  <h3 className="text-xl font-bold text-slate-900">{solution.title}</h3>
                </div>
                <p className="text-slate-600 mb-6">{solution.description}</p>
                <div className="flex flex-wrap gap-2">
                  <span className="text-xs px-2 py-1 rounded-full bg-blue-50 text-blue-600">风险评估</span>
                  <span className="text-xs px-2 py-1 rounded-full bg-blue-50 text-blue-600">数据分析</span>
                  <span className="text-xs px-2 py-1 rounded-full bg-blue-50 text-blue-600">风险报告</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <a
            href="https://u.sjcyx.com/queryReport?linkId=10140504619395260417"
            target="_blank"
            className="inline-block py-3 px-8 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg shadow-lg shadow-blue-500/20 transition-all duration-300"
          >
            立即体验解决方案
          </a>
        </div>
      </div>
    </section>
  )
}
